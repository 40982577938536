/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, SeparateLine, SeparateLineWrap, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"akce"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"bd9iov63ube"} parallax={false}>
        </Column>


        <Column className="css-1sgdxel --full pb--60 pt--60" name={"m4jnjxhb8g"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-q9nct1 pb--60 pt--60" name={"b4jm0n0qjnn"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/77e73751356140019b4d565f3186fcc1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/77e73751356140019b4d565f3186fcc1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/77e73751356140019b4d565f3186fcc1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/77e73751356140019b4d565f3186fcc1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/77e73751356140019b4d565f3186fcc1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/77e73751356140019b4d565f3186fcc1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/77e73751356140019b4d565f3186fcc1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/77e73751356140019b4d565f3186fcc1_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"font-weight: bold;\">Program v únoru 2025</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">3.2.2025</span><br>jednání s partnery v Pardubicích&nbsp;<br><span style=\"font-weight: bold;\">7.-8.2.2025</span><br>konference k případů dětí&nbsp;<br><span style=\"font-weight: bold;\">14.-15.2.2025</span><br>návštěva dětí - DD Krupka<br><span style=\"font-weight: bold;\">16.2.2025</span>&nbsp;<br>návštěva dětí - DD Střekov&nbsp;<br>návštěva dětí - DDÚ Hradec&nbsp;<br>jednání s partnery v Pardubicích<br><span style=\"font-weight: bold;\">19.2.2025</span><br>případová konference Olomouc<br><span style=\"font-weight: bold;\">20.2.2025<br></span>návštěva dětí - DD Boskovice<br><span style=\"font-weight: bold;\">21.2.2025&nbsp;</span><br>jednání s odborem soc. věcí<br><span style=\"font-weight: bold;\">22.2.2025<br></span>FC Baník Ostrava - DD Vrbno<br></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 btn-box--center" content={"<span style=\"font-weight: 700;\">příspěvek z DD Krupka</span>"} url={"https://www.facebook.com/share/p/16Bhq1rCGE/"} href={"https://www.facebook.com/share/p/16Bhq1rCGE/"}>
              </Button>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 btn-box--center" content={"<span style=\"font-weight: bold;\">více na INSTAGRAMU</span>"} url={"https://www.instagram.com/detivakci/"} href={"https://www.instagram.com/detivakci/"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"811q3hwml1c"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingTop":18,"paddingBottom":3}} name={"4m8xzk484x"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--26" content={"<span style=\"font-weight: bold;\">Podzimní pobyt&nbsp;v Janoušově<br>ve dnech 21.-24.11.2024</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w" style={{"paddingBottom":19,"paddingTop":13}} name={"ha8phe8wb2"}>
          
          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Podzimní pobyt Janoušov<br><span style=\"font-weight: bold;\">Dětský domov Litovel</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Podzimní pobyt Janoušov<br><span style=\"font-weight: bold;\">Dětský domov Černá Voda</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Podzimní pobyt Janoušov\n<br><span style=\"font-weight: bold;\">Dětský diagn. ústav Hr. Králové</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Podzimní pobyt Janoušov\n<br><span style=\"font-weight: bold;\">Dětský domov Krásná Lípa</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}